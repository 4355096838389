/* global styles go here */
html,
body {
  width: 100%;
  height: 100%;
  font-family: 'Roboto', sans-serif;
}

.DateInput {
  height: 54px;
  display: inline-flex !important;
}

//client dialog
.clientDialog .mdc-dialog__surface {
  min-width: 60vw !important;
}
//datepicker over rich textfield doesn't have the z-index needed
.DateRangePicker_picker {
  z-index: 99999 !important;
}

.datePickerError .DateRangePickerInput__withBorder {
  border: 1px solid var(--mdc-theme-error);
}
// TODO: move to shared-ui

//Overridden because the outlines are always gray in rmwc
.mdc-button--outlined {
  border: 1px solid var(--mdc-theme-primary) !important;
}

// Make select helper text look like TextField helper text
.mdc-select-helper-text {
  padding-left: 16px;
  padding-right: 16px;
  color: rgba(0, 0, 0, 0.6);
}

// To ensure that all cards, data tables, and elevated areas look the same
// the following rules standardize things.
.mdc-elevation--z1 {
  border-radius: 4px;
}

.mdc-data-table {
  border: 0;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mdc-data-table__row--selected {
  background-color: #addefa;
}
