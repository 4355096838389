@mixin mq-tablet {
  @media (max-width: 839px) and (min-width: 600px) {
    @content;
  }
}

@mixin mq-desktop {
  @media (min-width: 840px) {
    @content;
  }
}
