@import './mediaQueries.module.scss';

@mixin grid-margin($property) {
  #{$property}: var(--mdc-layout-grid-margin-phone) !important;

  @include mq-tablet {
    #{$property}: var(--mdc-layout-grid-margin-tablet) !important;
  }

  @include mq-desktop {
    #{$property}: var(--mdc-layout-grid-margin-desktop) !important;
  }
}

.margin {
  @include grid-margin(margin);
}

.marginTop {
  @include grid-margin(margin-top);
}

.marginRight {
  @include grid-margin(margin-right);
}

.marginBottom {
  @include grid-margin(margin-bottom);
}

.marginLeft {
  @include grid-margin(margin-left);
}

.padding {
  @include grid-margin(padding);
}

.paddingTop {
  @include grid-margin(padding-top);
}

.paddingRight {
  @include grid-margin(padding-right);
}

.paddingBottom {
  @include grid-margin(padding-bottom);
}

.paddingLeft {
  @include grid-margin(padding-left);
}
