.colorOnError {
  color: var(--mdc-theme-on-error) !important;
}

.colorOnPrimary {
  color: var(--mdc-theme-on-primary) !important;
}

.colorOnSecondary {
  color: var(--mdc-theme-on-secondary) !important;
}

.colorOnSurface {
  color: var(--mdc-theme-on-surface) !important;
}

.colorPrimary {
  color: var(--mdc-theme-primary) !important;
}

.colorSecondary {
  color: var(--mdc-theme-secondary) !important;
}

.backgroundBackground {
  background: var(--mdc-theme-background) !important;
}

.backgroundError {
  background: var(--mdc-theme-error) !important;
}

.backgroundPrimary {
  background: var(--mdc-theme-primary) !important;
}

.backgroundSecondary {
  background: var(--mdc-theme-secondary) !important;
}

.backgroundSurface {
  background: var(--mdc-theme-surface) !important;
}
