.supportFab {
  position: fixed !important;
  bottom: 25px !important;
  right: 25px !important;
}

.supportDialog .mdc-dialog__surface {
  min-width: 60vw !important;
  width: 60vw !important;
}
