.loginForm {
  padding: 15px;
}
.registrationForm {
  height: 53vh;
  padding: 15px;
}
.floatRight {
  float: right;
}
.googleButton {
  float: right;
}
