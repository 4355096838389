.relative {
  position: relative;
}

.icon {
  cursor: pointer;
  position: absolute;
  top: 24px;
  right: 24px;
}

.logo {
  width: 70%;
  height: auto;
}
.smallPadding {
  padding: 5px !important;
}
.mobileNavSection {
  padding: 0px !important;
}
