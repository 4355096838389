.navbar {
  padding: 15px;
}
.navbarMobileHidden {
  padding: 0px !important;
}
.profileBar {
  padding: 15px;
}
.navGridCell {
  width: 250px;
}
.editAvatarDialog .mdc-dialog__surface {
  min-width: 60vw !important;
  width: 60vw !important;
}
