.wide {
  width: 100%;
}

.center {
  display: flex;
  justify-content: center;
}

.right {
  display: flex;
  justify-content: flex-end;
}

.flex {
  display: flex;
}

.buttonLink {
  text-decoration: none;
  color: inherit;
  color: var(--mdc-theme-primary);
}
