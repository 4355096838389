.settings {
  height: 90vh;
  padding: 15px;
}
.floatRight {
  float: right;
}
.advancedSettingsHide {
  display: none;
}
.advancedSettingsShow {
  margin-top: 300px;
}
