.clientDialog .mdc-dialog__surface {
  min-width: 60vw !important;
  width: 60vw !important;
}
.floatRight {
  float: right;
}
.templateDialog .mdc-dialog__surface {
  min-width: 60vw !important;
  width: 60vw !important;
}
