:root {
  --mdc-shape-large-component-radius: 0;
  --mdc-shape-medium-component-radius: 4px;
  --mdc-shape-small-component-radius: 0;
  --mdc-theme-background: #f7f7f7;
  --mdc-theme-error: #dd2c00;
  --mdc-theme-on-error: #ffffff;
  --mdc-theme-on-primary: #ffffff;
  --mdc-theme-on-secondary: #000000;
  --mdc-theme-on-surface: #000000;
  --mdc-theme-primary: #237baf;
  --mdc-theme-secondary: #2f96e4;
  --mdc-theme-surface: #ffffff;
  --mdc-layout-grid-margin-desktop: 24px;
  --mdc-layout-grid-gutter-desktop: 24px;
  --mdc-layout-grid-column-width-desktop: 70px;
  --mdc-layout-grid-margin-tablet: 16px;
  --mdc-layout-grid-gutter-tablet: 16px;
  --mdc-layout-grid-column-width-tablet: 70px;
  --mdc-layout-grid-margin-phone: 16px;
  --mdc-layout-grid-gutter-phone: 16px;
  --mdc-layout-grid-column-width-phone: 70px;
}

.mdc-text-field--focused:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid)
  .mdc-floating-label {
  color: var(--mdc-theme-primary) !important;
}

.mdc-select--focused:not(.mdc-select--disabled):not(.mdc-text-field--invalid)
  .mdc-floating-label {
  color: var(--mdc-theme-primary) !important;
}

.mdc-data-table__cell,
.mdc-data-table__header-cell {
  vertical-align: middle;
}

.mdc-button {
  border-radius: var(--mdc-shape-small-component-radius);
}

.mdc-snackbar__actions .mdc-button.mdc-button {
  // slighly lighter than secondary color for contrast
  color: #46a1e7;
}

.mdc-data-table__header-cell {
  font-size: 12px;
  color: #757575;
}

.mdc-typography--caption {
  color: rgba(0, 0, 0, 0.6);
}

.mdc-select--invalid + .mdc-select-helper-text--validation-msg {
  color: #b00020;
  color: var(--mdc-theme-error, #b00020);
  padding-left: 16px;
}
